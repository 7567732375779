import device from '~/mixins/device.js'

export default {
  mixins: [device],
  props: {
    title: {
      type: String,
      required: false,
    },

    cards: {
      type: Array,
      required: true,
    },
  },

  components: {
    hotOrganicCardNumber: () => import('~/components/Molecules/Organic/CardNumber/CardNumber.vue'),
  },
}
